export const environment = {
    production: true,
    hmr: false,
    appConfig: './config/appconfig.development.json',
    ApplicationInsights: {
        isEnabled: true,
        instrumentationKey: '3fee6c45-6a54-4acf-ae37-89d7b85262f0',
        cloudRoleName: "Research Development Frontend"
    },
    grafana: {
        isEnabled: true,
        url: 'https://faro-collector-prod-sa-east-1.grafana.net/collect/b37f5d78407fddfa868c49ad33aa6772',
        environmentName: 'development',
    }
};
